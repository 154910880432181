// consumer_evection_evectionDC 根据出差单号获取详情
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-journey-controller/getEvectionDetailUsingPOST
//原consumer_journey_getEvectionDetail接口
const __request = require(`./__request/__request_contentType_json`);
const consumer_evection_evectionDC = (pParameter) => {
    if (!pParameter) pParameter = {};

    let datas = {};
    datas.evectionNo =  pParameter.evectionNo ? pParameter.evectionNo : '';

    const p = {
        method: 'post',
        urlSuffix: '/consumer/evection/evectionDC',
        data: datas
    };

    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_evection_evectionDC;
